<template>
    <div class="relative">
        <v-alert
            type="error"
            class="mb-5"
            :value="errorMessage">
            {{ errorMessage }}
        </v-alert>

        <a
            href="https://support.buyerbridge.com/knowledge/microsoft-start-autos-premium-listings#frequently-asked-questions"
            target="_blank"
            class="top-right">
            <div class="mr-2">
                <icon
                    name="question-circle"
                    color="#00A2EA"
                    size="20" />
            </div>
            <div class="blue-text">
                FREQUENTLY ASKED QUESTIONS
            </div>
        </a>

        <div class="mb-5">
            <h2>CPC Biling Agreement</h2>
            <p class="mt-2">
                Microsoft's Start Autos program is billed directly through BuyerBridge on a CPC basis.
                By opting into the program you will be billed monthly in arrears for accumulated clicks
                and will receive regular reports to keep track of current costs. You can pause the program
                at any time by removing the product via the subscription panel in Dealer Settings and
                resuming it by re-onboarding the product.
            </p>
        </div>

        <div class="agreement-content">
            <div class="pa-4">
                <div v-html="parsedLegalBody" />
            </div>
        </div>

        <div class="mt-2 mb-5 flex">
            <div>
                <div class="flex mt-4">
                    <div>
                        <styled-checkbox
                            :value="true"
                            :disabled="checkBox || loading"
                            :checked="checkBox"
                            square
                            @change="handleCheckBoxChange" />
                    </div>
                    <div class="mb-1 ml-2">
                        Click here to confirm that you accept the billing agreement above and
                        understand that you are responsible for all Microsoft Start Autos
                        advertising costs incurred for {{ dealer.name }}.
                    </div>
                </div>
            </div>
        </div>

        <div class="layout align-center justify-between">
            <back-button @click="goToPreviousStep" />
            <div>
                <span class="mr-2">Your rate <b>${{ microsoftAutosRateDecimial }} per click</b></span>
                <styled-button
                    class="ml-auto"
                    :disabled="!checkBox"
                    :loading="loading"
                    @click="handleContinue">
                    Accept Agreement & Continue
                </styled-button>
            </div>
        </div>
    </div>
</template>

<script>
import StyledButton from '@/components/globals/StyledButton';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import BackButton from '@/components/globals/BackButton';
import { mapActions, mapState } from 'vuex';
import Icon from '@/components/globals/Icon';

export default {
    name: 'GoogleBusinessProfile',
    components: {
        StyledButton,
        StyledCheckbox,
        BackButton,
        Icon
    },
    data() {
        return {
            loading: false,
            checkBox: null,
            errorMessage: '',
            microsoftAutosRate: 1.25,
            legalAgreementData: null,
            leagalAgreementName: 'microsoft_marketplace_premium',
            parsedLegalBody: null,
        };
    },
    computed: {
        ...mapState({
            dealer: (state) => state.dealer.currentDealer,
            agency: (state) => state.agency.currentAgency,
        }),
        microsoftAutosRateDecimial() {
            return this.microsoftAutosRate.toFixed(2);
        }
    },
    created() {
        this.init();
    },
    methods: {
        ...mapActions([
            'goToPreviousStep',
            'goToNextStep'
        ]),
        async handleContinue() {
            this.goToNextStep();
        },
        handleCheckBoxChange(event) {
            if (event.target.checked) {
                this.acceptAgreement();
            }
        },
        async init() {
            await this.getLegalAgreementData();
            await this.getDealerLegalAgreementStatus();
        },
        async getLegalAgreementData() {
            try {
                this.loading = true;
                const agreement = await this.$apiRepository.getLegalAgreement(this.leagalAgreementName);
                this.legalAgreementData = agreement;
                this.parseLegalBody();
            } catch (error) {
                console.error('Error retrieving Legal agreement data', error);
                this.errorMessage = 'Error retrieving Legal agreement data';
            } finally {
                this.loading = false;
            }
        },
        async getDealerLegalAgreementStatus() {
            try {
                this.loading = true;
                const response = await this.$apiRepository.getDealerAgreement(this.dealer.id, this.leagalAgreementName);
                this.checkBox = !!response.data?.id;
            } catch (error) {
                console.error('Error retrieving legal agreement status', error);
                this.errorMessage = 'Error retrieving legal agreement status';
            } finally {
                this.loading = false;
            }
        },
        async acceptAgreement() {
            try {
                this.loading = true;
                const data = {
                    dealerId: this.dealer.id,
                    legalAgreementName: this.legalAgreementData.name,
                    document: this.parsedLegalBody,
                };
                const response = await this.$apiRepository.acceptDealerAgreement(data);
                if (response.data?.success) {
                    this.checkBox = true;
                }
            } catch (error) {
                console.error('Error updating the legal agreement status', error);
                this.errorMessage = 'Error updating the legal agreement status';
            } finally {
                this.loading = false;
            }
        },
        parseLegalBody() {
            const variables = {
                '{{ agency.name }}': this.agency.name,
                '{{ dealer.name }}': this.dealer.name,
                '{{ microsoftAutosRateDecimial }}': this.microsoftAutosRate.toFixed(2)
            };

            if (this.legalAgreementData) {
                this.parsedLegalBody = this.legalAgreementData.body.replace(/{{ agency.name }}|{{ dealer.name }}|{{ microsoftAutosRateDecimial }}/g, (matched) => {
                    return variables[matched];
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.top-right {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.relative {
    position: relative;
}

.blue-text {
    color: rgba(0,162,234,1);
    font-size: 13px;
}

.agreement-content {
    color: $gray-dark;
    border: 1px solid rgba($gray, 0.4);
    font-size: 14px;
    background-color: rgba($gray, 0.05);
    font-family:'Courier New', Courier, monospace;
    h1, h2, h3, h4 {
        font-weight: bold;
        margin-bottom: 1rem;
        color: $gray-dark;
    }
}
</style>